import React from 'react';
import { Link } from 'react-router-dom';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li>
                    <a href="#home">Home</a>
                </li>                
                {/* <li><Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link></li>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/service-one"}>Services</Link>
                </li>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Portfolio</Link>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li> */}
                <li><a href="#About">About Us</a></li>
                <li>
                    <a href="#Service">Services</a>
                </li>
                <li>
                    <a href='#Portfolio'>Portfolio</a>
                </li>
                <li><a href='#contact'>Contact</a></li>
                <li><a href='https://join.skype.com/invite/pO27cyNEUGtP' target="_blank">Hire me</a></li>
            </ul>
        </nav>
    )
}

export default Nav;