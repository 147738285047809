import React from "react";
import { Link } from "react-router-dom";
import FormOne from "../contact/FormOne";

const AboutOne = () => {
  return (
    <section
      className="section section-padding-equal bg-color-light"
      id="About"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle">About Us</span>
                <h2 className="title mb--40">What We Do</h2>
                <p>
                  Welcome to Uokal InfoSolution, where we bring a world of IT
                  solutions to your doorstep. With a wealth of experience and a
                  global presence, we are your trusted partner for all things
                  tech. Our comprehensive IT services, including website
                  development, software solutions, and Work-from-home support,
                  cater to your every technological need. From web design to
                  cloud solutions and cybersecurity, we offer a wide array of
                  services that empower your business to thrive in the digital
                  age. With over 8+ years in the industry, we've honed our
                  skills and built a reputation for excellence. Our team of
                  seasoned professionals excels in cutting-edge technologies,
                  ensuring your projects are in safe and capable hands. While we
                  proudly call Ahmedabad home, our impact knows no borders.
                  We've successfully collaborated with businesses worldwide,
                  delivering IT solutions that transcend geographical
                  boundaries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
