import React from "react";
import TeamData from "../../data/team/TeamData.json";

const allData = TeamData;

const TeamOne = () => {
  return (
    <>
      <div className="row">
        {allData.map((data) => (
          <div className="col-xl-3 col-sm-6" key={data.id}>
            <div className="team-grid">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + data.thumb}
                  alt={data.title}
                />
              </div>
              <div className="content">
                <h5 className="title">{data.title}</h5>
                <span
                  className="designation"
                  dangerouslySetInnerHTML={{ __html: data.designation }}
                ></span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TeamOne;
