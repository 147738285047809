import React from "react";
import { Link } from "react-router-dom";
import {
  FaSkype,
  FaMailBulk,
  FaSlack,
  FaLinkedin,
  FaMapMarked,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`} id="contact">
      <div className="container pt-5">
        {/* <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://join.skype.com/invite/pO27cyNEUGtP" target="_blank"><FaSkype /></a></li>
                            <li><a href="mailto:uokalinfosolution@gmail.co" target="_blank"><FaMailBulk /></a></li>
                            <li><a href="https://app.slack.com/client/T0333ND3U9H/D033A2D02LW/rimeto_profile/U033GMW1SCT" target="_blank"><FaSlack /></a></li>
                            <li><a href="https://www.linkedin.com/in/umang-l-923729195/" target="_blank"><FaLinkedin /></a></li>
                            <li></li>
                        </ul>
                    </div>
                </div> */}
        <div className="footer-main">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div className="footer-widget text-center">
                <div className="footer-newsletter">
                  <h6 className="widget-title">Address</h6>
                  <p>
                    <a
                      href="https://g.page/uokal-infosolution?share"
                      target="_blank"
                    >
                      <FaMapMarked />
                    </a>{" "}
                    Harekrushna Residency, New Maninagar, <br/>Ahmedabad, Gujarat
                    382449
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">Contact Us</h6>
                    <div className="footer-social-link">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            href="https://join.skype.com/invite/pO27cyNEUGtP"
                            target="_blank"
                          >
                            <FaSkype />
                          </a>
                        </li>
                        <li>
                          <a
                            href="mailto:uokalinfosolution@gmail.com"
                            target="_blank"
                          >
                            <FaMailBulk />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/umang-l-923729195/"
                            target="_blank"
                          >
                            <FaLinkedin />
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            href="https://api.whatsapp.com/send?phone=+917990806771&text=Welcome to Uokal Infosolution"
                            target="_blank"
                          >
                            <FaWhatsapp />
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by{" "}
                  <a href="https://www.uokalinfosolution.com/">
                    Uokal InfoSolution
                  </a>
                  .
                </span>
              </div>
            </div>
            {/* <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
