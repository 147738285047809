import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BrandItem from './BrandItem';


const BrandOne = () => {
    return (
        <div className="section section-padding-2 bg-color-light">
        <div className="container">
             <div className="section-heading heading-left mb-0">
                                <span className="subtitle">Top Clients</span>
                                <h2 className="title mb--40">We’ve built solutions for...</h2>
                            </div>
            <div className='row align-items-center'>
               <BrandItem />
            </div>
        </div>
        <ul className="shape-group-1 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="bubble" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="bubble" /></li>
            </ul>
    </div>
    )
}

export default BrandOne;