import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Data = [
  {
    image: "/images/brand/brand-1.png",
  },
  {
    image: "/images/brand/brand-2.png",
  },
  {
    image: "/images/brand/brand-3.png",
  },
  {
    image: "/images/brand/brand-4.png",
  },
  {
    image: "/images/brand/brand-5.png",
  },
  {
    image: "/images/brand/brand-6.png",
  },
  {
    image: "/images/brand/brand-7.png",
  },
  {
    image: "/images/brand/brand-8.png",
  },
  {
    image: "/images/brand/brand-9.png",
  },
  {
    image: "/images/brand/brand-10.png",
  },
  {
    image: "/images/brand/brand-11.png",
  },
  {
    image: "/images/brand/brand-12.png",
  },
];
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const BrandItem = () => {
  return (
    <>
      <Slider {...settings}>
        {Data.map((data, index) => (
          <div key={index}>
            <div className="brand-grid">
              <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default BrandItem;
